<template>
    <div style="background-color: #f6f7fa;height:100vh;">
        <b-container fluid class="px-0 py-0">
            <header
                class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line px-2 py-1 bg-white">
                <b-row class="justify-content-between">
                    <b-col class="align-self-center" lg="4" md="4">
                        <h1 class="text-dark text-darken-7 fw-bold-700 size16 mb-0">
                            Cetak Struk Retur
                        </h1>
                    </b-col>
                    <b-col class="align-self-center text-right" lg="8" md="8">
                        <div class="d-flex justify-content-end">
                            <b-button class="py-2 btn-sm border-8 mr-1" @click="printDeliveryNumbers">
                                Cetak Surat Jalan
                            </b-button>
                            <b-button class="py-2 btn-sm border-8 mr-1" @click="printInvoice">
                                Cetak di Android
                            </b-button>
                            <b-button class="mr-1 px-2 py-2 btn-sm pt-1 border-8" @click="printA5">
                                Cetak di PC
                            </b-button>
                            <b-button class="mr-1 px-2 py-2 btn-sm pt-1 border-8" @click="printThermalPC">
                                Cetak Thermal di PC
                            </b-button>
                            <b-button class="bg-white px-3 py-2 pt-1 border-8" @click="redirect">
                                Selesai
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </header>
        </b-container>
        <Retur :detail-retur="detailRetur" :merchant="merchant" />
        <ReturA5 :detail-retur="detailRetur" :merchant="merchant" />
        <b-modal id="modal-delivery-numbers" hide-header hide-footer centered title="Vertically Centered" ok-only
            ok-title="Accept">
            <div class="p-2 d-flex justify-content-between align-items-center">
                <h4 class="text-dark font-bold-700 size16 mb-0">
                    Pilih Barang Untuk Surat Jalan
                </h4>
                <div role="button" @click="$bvModal.hide('modal-delivery-numbers')">
                    <feather-icon size="24" class="text-dark font-weight-bolder" icon="XIcon" />
                </div>
            </div>
            <b-container class="">
                <b-row class="p-2">
                    <b-col cols="12">
                        <table class="table table-hover">
                            <thead>
                                <tr class="border-bottom">
                                    <th scope="col" class="d-flex text-left">
                                        <b-form-checkbox v-model="allSelected" @click="selectAll" />
                                        Item
                                    </th>
                                    <th scope="col" class="text-center">
                                        Qty
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in detailRetur.items" :key="index" class="border-bottom">
                                    <td class="border-0 d-flex" v-if="detailRetur.type === 'order'">
                                        <b-form-checkbox v-model="deliveryIds" :value="item.item.uuid" @change="select" />
                                        {{ item.item.name || '-' }}
                                    </td>
                                    <td class="border-0 d-flex" v-else-if="detailRetur.type === 'incoming_stock'">
                                        <b-form-checkbox v-model="deliveryIds" :value="item.item.uuid" @change="select" />
                                        {{ item.item.product.name || '-' }}
                                    </td>
                                    <td class="border-0 text-center" v-if="detailRetur.type === 'order'">
                                        {{ item.return_qty.toString().replace('.', ',') }} {{ item.item.unit }}
                                    </td>
                                    <td class="border-0 text-center" v-else-if="detailRetur.type === 'incoming_stock'">
                                        {{ item.return_qty.toString().replace('.', ',') }} {{ item.item.product.uom.name }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- Memo -->
                        <div class="custom__form--input mb-2">
                            <label class="text-dark" for="memo">Memo</label>
                            <quil-editor :payload="memo" @contentText="getContentText" />
                        </div>

                        <div class="text-right">
                            <b-button variant="dark" :disabled="deliveryIds.length == 0" @click="printInvoiceDelivery">
                                Cetak
                            </b-button>
                        </div>
                        <ReturA5Delivery :detail-retur="detailRetur" :merchant="merchant" :data-delivery="dataDelivery"
                            :delivery-numbers="deliveryNumbersIds" :memo="memo" />
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>

<script>
import printJS from "print-js"
import {
    BContainer, BButton, BRow, BCol, BSpinner, BFormCheckbox, BImg, BFormInput, BFormGroup, BFormTextarea
} from 'bootstrap-vue'
import QuilEditor from '@/components/QuilEditor.vue'

import Retur from '@/components/Retur.vue'
import ReturA5 from '@/components/ReturA5.vue'
import ReturA5Delivery from '@/components/ReturA5Delivery.vue'

const { hostname, protocol, port } = window.location

export default {
    title() {
        return 'Retur'
    },
    components: {
        BButton,
        BRow,
        BCol,
        BContainer,
        BFormCheckbox,
        BImg,
        BFormInput,
        BFormGroup,
        BFormTextarea,
        BSpinner,
        Retur,
        ReturA5,
        ReturA5Delivery,
        QuilEditor,
    },
    data() {
        return {
            detailRetur: {},
            merchant: {},
            result: {},
            selected: [],
            allSelected: false,
            deliveryIds: [],
            dataDelivery: [],
            deliveryNumbers: [],
            deliveryNumbersIds: [],
            memo: '',
        }
    },
    watch: {
        deliveryIds(value) {
            this.deliveryNumbersIds = [];
            const result = this.detailRetur.items.filter(item => value.includes(item.item.uuid)); // Use item directly here
            this.dataDelivery = result;

            result.forEach(element => {
                if (element.item.delivery_numbers && element.item.delivery_numbers.length) { // Access 'item' property correctly
                    this.deliveryNumbersIds.push(...element.item.delivery_numbers.map(e => e.delivery_number_note));
                }
            });
        },
        allSelected() {
            this.selectAll();
        },
    },
    mounted() {
        this.getDetail()
        this.getMerchant()
        this.getBranch()
    },
    methods: {
        getDetail() {
            const uuid = this.$route.params.id;
            this.$store.dispatch(`returs/getData`, {
                uuid: uuid,
            })
                .then(result => {
                    this.detailRetur = result.data.data;
                    console.log(this.detailRetur,'wkeokweok')
                }).catch(err => {
                    console.log(err);
                });
        },
        getMerchant() {
            this.$store.dispatch('profile/getMerchant')
                .then(result => {
                    this.merchant = result.data.data;
                }).catch(err => {
                    // eslint-disable-next-line no-console
                    console.log(err)
                })
        },
        getContentText(val) {
            this.memo = val;
        },
        selectAll() {
            this.deliveryIds = [];
            this.dataDelivery = [];

            if (this.allSelected) {
                this.detailRetur.items.forEach(item => {
                    this.deliveryIds.push(item.item.uuid);
                    this.dataDelivery.push(item);
                });
            }
        },
        select() {
            this.allSelected = false
            return true
        },
        printDeliveryNumbers() {
            this.$bvModal.show('modal-delivery-numbers')
        },
        async printA5() {
            await this.$htmlToPaper('contentPrintA5')
        },
        async printInvoiceDelivery() {
            await this.$htmlToPaper('contentPrintA5Delivery')
        },
        async printThermalPC() {
            await printJS({
                printable: "thermal-print",
                type: "html",
                css: [
                    `${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/bootstrap.css`,
                    `${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/portrait.css`,
                ],
                scanStyles: false
            });
        },
        printInvoice() {
            const { hostname, protocol, port } = window.location
            // console.log(`${protocol}//${hostname}:${port}`)
            let element = `<link rel='stylesheet' href='${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/bootstrap.css'>`
            element += `<link rel='stylesheet' href='${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/styles.css'>`
            element += `<link rel='stylesheet' href='${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/thermal-mobile.css'>`
            element += this.$children[0].$refs.contentPrint.innerHTML
            window.location.href = `print://escpos.org/escpos/bt/print?srcTp=uri&srcObj=html&numCopies=1&src="data:text/html,${element}"`
        },
        redirect() {
            window.location.href = '/retur'
        }
    }
}
</script>

<style lang="scss" scoped>
body {
    background-color: #f6f7fa;
}

button.bg-white {
    background-color: #FFFFFF !important;
    border: 1px solid;
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

#modal-delivery-numbers {
    .modal-dialog {

        // max-width: 603px;
        .modal-content {
            // max-width: 603px;
            background: #FCFCFC;
            border-radius: 24px !important;

            .modal-body {
                padding: 0;

                .form-group {
                    label {
                        font-size: 14px;
                        color: $dark;
                        font-weight: 600;
                    }

                    input {
                        background: #E7E7ED;
                        border: none;
                        border-radius: 8px;
                        height: 52px;
                        color: $dark;
                        font-weight: 600;
                    }
                }

                .button__group {
                    display: flex;
                    width: 100%;

                    .button__cancel {
                        background-color: #FFFFFF !important;
                        border-color: #fff !important;
                        color: $dark !important;
                        box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
                        border-radius: 0px 0px 0px 16px;
                        width: 100%;
                        height: 72px;
                        font-size: 16px;
                        font-weight: 800;
                    }

                    .button__save {
                        background-color: #45B6AB !important;
                        border-color: #45B6AB !important;
                        box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
                        border-radius: 0px 0px 16px 0px;
                        width: 100%;
                        height: 72px;
                        font-size: 16px;
                        font-weight: 800;
                    }

                }
            }
        }
    }
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
    background-color: transparent;
    border-bottom: none;
}

.table {
    thead {
        tr {
            th {
                border-bottom: 1px solid #ebe9f1;
                background-color: transparent;
                border-top: none;
                padding: .5rem 0;
                color: #7A7F94;
                font-weight: 500;
                text-transform: capitalize;
                font-size: 12px;
            }
        }
    }

    tbody {
        tr {
            td {
                border-top: none;
                color: $dark;
                font-size: 12px;
                font-weight: 600;
                padding: 1.5rem 0;
                border-bottom: 1px solid #ebe9f1;

                &:nth-child(2) {
                    width: 12rem;
                }
            }
        }
    }
}
</style>