<template>
    <b-container fluid style="position:absolute;bottom:0;height: calc(100vh - 223px) !important;">
        <b-row class="justify-content-center" style="background-color: #f6f7fa;">
            <b-col cols="12" lg="5">
                <!-- Print Thermal -->
                <div class="d-none">
                    <b-container id="thermal-print" class="h-100 bg-wave">
                        <div class="pt-md-3 px-lg-3 px-2 pb-3">
                            <!-- Logo -->
                            <b-img v-if="detailRetur.transaction && detailRetur.transaction.branch.photo_url.includes('png') || detailRetur.transaction.branch.photo_url.includes('jpg')" :src="detailRetur.transaction.branch.photo_url" alt="logo" class="custom-img"
                                style="margin-left: 145px; width: 100px; height: auto;" />

                            <!-- Detail -->
                            <b-row class="mx-0 mt-2">
                                <b-col style="padding: 0" lg="12">
                                    <h6 class="size10 fw-bold-500 text-black mb-1 mb0" v-if="detailRetur.type === 'order'">
                                        No. Invoice: {{ detailRetur.transaction.invoice_number || '-' }}
                                    </h6>
                                    <h6 class="size10 fw-bold-500 text-black mb-1 mb0">
                                        No. Retur: {{ detailRetur.retur_number || '-' }}
                                    </h6>
                                    <h6 class="size10 fw-bold-500 text-black mb-1 mb0">
                                        Tanggal: {{ detailRetur.date || '-' }}
                                    </h6>
                                    <h6 class="size10 fw-bold-500 text-black mb-1 mb0" v-if="detailRetur.type === 'order'">
                                        Sales: {{ detailRetur.transaction.seller_name || '-' }}
                                    </h6>
                                    <h6 class="size10 fw-bold-500 text-black mb-1 mb0" v-if="detailRetur.type === 'order'">
                                        Pelanggan: {{ detailRetur.transaction.customer_name || '-' }}
                                    </h6>
                                    <h6 class="size10 fw-bold-500 text-black mb-1 mb0"
                                        v-else-if="detailRetur.type === 'incoming_stock'">
                                        Pemasok: {{ detailRetur.transaction.supplier_name || '-' }}
                                    </h6>
                                </b-col>
                                <b-col style="padding: 0" lg="12">
                                    <hr class="mb-25" style="border-top: 1px solid #000;">
                                </b-col>
                            </b-row>

                            <!-- List Produk -->
                            <table style="width: 100%;">
                                <tbody>
                                    <tr v-for="(item, index) in detailRetur.items" :key="`0-${index}`">
                                        <td class="padding--print">
                                            <h6 class="size10 fw-bold-700 text-black px-0"  v-if="detailRetur.type === 'order'">
                                                {{ item.item.name }}
                                            </h6>
                                            <h6 class="size10 fw-bold-700 text-black px-0"  v-else-if="detailRetur.type === 'incoming_stock'">
                                                {{ item.item.product.name }}
                                            </h6>
                                            <table style="width: 100%;">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 20%;">
                                                            <h6 class="size10 fw-bold-500 text-black px-0" v-if="detailRetur.type === 'order'">
                                                                {{ item.return_qty.toString().replace('.', ',') }} {{
                                                                    item.item.unit }}
                                                            </h6>
                                                            <h6 class="size10 fw-bold-500 text-black px-0" v-else>
                                                                {{ item.return_qty.toString().replace('.', ',') }} {{
                                                                    item.item.product.uom.name }}
                                                            </h6>
                                                        </td>
                                                        <td style="text-align: right;width: 25%;">
                                                            <h6 class="size10 fw-bold-500 text-black px-0">
                                                                {{ item.item.price | formatInvoice }}
                                                            </h6>
                                                        </td>
                                                        <td style="text-align: right;width: 25%;">
                                                            <h6 class="size10 fw-bold-500 text-black px-0">
                                                                {{ item.return_value | formatInvoice }}
                                                            </h6>
                                                        </td>
                                                        <td style="width: 13%;"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr class="my-0" style="border-top: 1px solid #000;">

                            <!-- Total Retur -->
                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td class="py-25">
                                            <h6 class="text-black size12 mb-0 fw-bold-700 grand--total"
                                                style="width: 100px;">
                                                Total Retur
                                            </h6>
                                        </td>
                                        <td class="py-25 text-right">
                                            <h6 class="text-black size12 mb-0 fw-bold-700 grand--total">
                                                {{ detailRetur.return_value | formatAmount }} 
                                            </h6>
                                        </td>
                                        <td style="width: 15%;"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-container>
                </div>

                <!-- Print Android -->
                <b-container id="contentPrint" class="h-100 bg-wave">
                    <div id="contentPrint" ref="contentPrint" class="pt-md-3 px-lg-3 px-2 pb-3">
                        <!-- Logo -->
                        <b-img v-if="detailRetur.transaction && detailRetur.transaction.branch.photo_url.includes('png') || detailRetur.transaction.branch.photo_url.includes('jpg')" :src="detailRetur.transaction.branch.photo_url" alt="logo" class="custom-img"
                            style="margin-left: 145px; width: 100px; height: auto;" />

                        <!-- Detail -->
                        <b-row class="mt-2">
                            <b-col lg="12">
                                <h6 class="size10 fw-bold-500 text-black mb-1 mb0" v-if="detailRetur.type === 'order'">
                                    No. Invoice: {{ detailRetur.transaction.invoice_number || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1 mb0">
                                    No. Retur: {{ detailRetur.retur_number || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1 mb0">
                                    Tanggal: {{ detailRetur.date || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1 mb0" v-if="detailRetur.type === 'order'">
                                    Sales: {{ detailRetur.transaction.seller_name || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1 mb0" v-if="detailRetur.type === 'order'">
                                    Pelanggan: {{ detailRetur.transaction.customer_name || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1 mb0"
                                    v-else-if="detailRetur.type === 'incoming_stock'">
                                    Pemasok: {{ detailRetur.transaction.supplier_name || '-' }}
                                </h6>
                            </b-col>
                            <b-col lg="12">
                                <hr class="mb-25" style="border-top: 1px solid #000;">
                            </b-col>
                        </b-row>

                        <!-- List Produk -->
                        <b-row>
                            <b-col lg="12">
                                <div v-for="(item, index) in detailRetur.items" :key="index" class="mb-25">
                                    <div class="d-flex" style="height: 2rem">
                                        <h6 class="size10 fw-bold-700 text-black px-0" v-if="detailRetur.type === 'order'">
                                            {{ item.item.name }}
                                        </h6>
                                        <h6 class="size10 fw-bold-700 text-black px-0" v-else-if="detailRetur.type === 'incoming_stock'">
                                            {{ item.item.product.name }}
                                        </h6>
                                    </div>
                                    <div class="d-flex" style="height: 2rem">
                                        <h6 class="size10 fw-bold-500 text-black col-3 px-0"
                                            v-if="detailRetur.type === 'order'">
                                            {{ item.return_qty.toString().replace('.', ',') }} {{
                                                item.item.unit }}
                                        </h6>
                                        <h6 class="size10 fw-bold-500 text-black col-3 px-0" v-else>
                                            {{ item.return_qty.toString().replace('.', ',') }} {{
                                                item.item.product.uom.name }}
                                        </h6>
                                        <h6 class="size10 fw-bold-500 text-black col-3 px-0">
                                            {{ item.item.price | formatInvoice }}
                                        </h6>
                                        <h6 class="size10 fw-bold-500 text-black col-3 px-0 text-right">
                                            {{ item.return_value | formatInvoice }}
                                        </h6>
                                    </div>
                                </div>
                            </b-col>
                            <b-col lg="12">
                                <hr class="mt-25" style="border-top: 1px solid #000;">
                            </b-col>
                        </b-row>
                        <!-- <hr class="my-0" style="border-top: 1px solid #000;"> -->

                        <!-- Total Retur -->
                        <b-row>
                            <b-col lg="12">
                                <div class="d-flex justify-content-between" style="margin-bottom: 0.5rem;">
                                    <h6 class="text-black size12 mb-0 fw-bold-700">
                                        Total Retur
                                    </h6>
                                    <h6 class="text-black size10 mb-0 fw-bold-700">
                                        {{ detailRetur.return_value | formatAmount }}
                                    </h6>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BContainer,
        BImg,
    },
    data() {
        return {
        }
    },
    props: {
        detailRetur: {
            type: Object,
        },
        merchant: {
            type: Object,
        },
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style scoped>
p {
    margin-bottom: 0;
}

@media screen and (min-width: 500px) {
    .custom-img {
        margin-left: 0px !important;
    }
}

@media screen and (min-width: 500px) {
    .mb0 {
        margin-bottom: 0.5rem !important;
    }
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.text-black {
    color: #000;
}

.bg-wave {
    background-image: url('../assets/images/wave-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

button {
    &.bg-white {
        background-color: #FFFFFF !important;
        border: 1px solid $light--3 !important;
        color: $dark !important;
    }
}

label {
    font-size: 14px;
    color: $dark;
    font-weight: 600;
}

.vs__open-indicator {
    fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
    height: 52px;
    background: #EFF1F5;
    border: 1px solid #E4E5EC;
    border-radius: 8px;
}

.vs__dropdown-toggle {
    height: 52px;
    background-color: #EFF1F5;
    border: 1px solid #E4E5EC;
    border-radius: 8px;
}</style>
