<template>
    <b-container class="mt-5 pt-5 d-none">
        <b-row id="print-me" class="justify-content-center pt-2" style="background-color: #f6f7fa;">
            <b-col id="contentPrintA5" ref="contentPrintA5" cols="12" lg="10">
                <b-container class="h-100 bg-white" style="letter-spacing: 1px;">
                    <div class="pt-5 px-lg-3 px-2 pb-3">
                        <b-img v-if="detailRetur.transaction && detailRetur.transaction.branch.photo_url.includes('png') || detailRetur.transaction.branch.photo_url.includes('jpg')" :src="detailRetur.transaction.branch.photo_url" alt="logo"
                            style="width: 100px;" />

                        <b-row class="mt-4">
                            <b-col lg="6" md="6" cols="6">
                                <h6 class="size10 fw-bold-500 text-black mb-1" v-if="detailRetur.type === 'order'">
                                    No. Invoice: {{ detailRetur.transaction.invoice_number || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1">
                                    No. Retur: {{ detailRetur.retur_number || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1">
                                    Tanggal: {{ detailRetur.date || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1" v-if="detailRetur.type === 'order'">
                                    Sales: {{ detailRetur.transaction.seller_name || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1" v-if="detailRetur.type === 'order'">
                                    Pelanggan: {{ detailRetur.transaction.customer_name || '-' }}
                                </h6>
                                <h6 class="size10 fw-bold-500 text-black mb-1"
                                    v-else-if="detailRetur.type === 'incoming_stock'">
                                    Pemasok: {{ detailRetur.transaction.supplier_name || '-' }}
                                </h6>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1 ml-0" style="margin-right: 6rem;">
                            <b-col lg="12" class="pl-0">
                                <table class="table table-hover mb-0">
                                    <thead>
                                        <tr class="border-bottom"
                                            style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;">
                                            <th scope="col" class="text-left"
                                                style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;width:16rem">
                                                Item
                                            </th>
                                            <th scope="col" class="text-center"
                                                style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;">
                                                Qty
                                            </th>
                                            <th scope="col" class="text-center"
                                                style="border-bottom: 1px solid #000 !important;border-top: 1px solid #000 !important;">
                                                Harga Satuan
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in detailRetur.items" :key="index">
                                            <td class="border-0 pb-0 pt-1" v-if="detailRetur.type === 'order'">
                                                {{ item.item.name }}
                                            </td>
                                            <td class="border-0 pb-0 pt-1"
                                                v-else-if="detailRetur.type === 'incoming_stock'">
                                                {{ item.item.product.name }}
                                            </td>
                                            <td class="border-0 pb-0 pt-1 text-center" v-if="detailRetur.type === 'order'">
                                                {{ item.return_qty.toString().replace('.', ',') }} {{ item.item.unit }}
                                            </td>
                                            <td class="border-0 pb-0 pt-1 text-center"
                                                v-if="detailRetur.type === 'incoming_stock'">
                                                {{ item.return_qty.toString().replace('.', ',') }} {{
                                                    item.item.product.uom.name }}
                                            </td>
                                            <td class="border-0 pb-0 pt-1 text-center">
                                                {{ item.item.price | formatInvoice }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr class="my-1" style="border-top: 1px solid #000;">
                                <b-row align-h="end">
                                    <b-col cols="6" style="padding-right: 24px;">
                                        <div class="d-flex justify-content-between">
                                            <h6 class="text-black size16 mb-0 fw-bold-700">
                                                Total Retur
                                            </h6>
                                            <h6 class="text-black size16 mb-0 fw-bold-700">
                                                {{ detailRetur.return_value | formatAmount }}
                                            </h6>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <b-row class="mt-5">
                            <b-col cols="6">
                                <h6 class="text-black size16">
                                    TTD Penyerah
                                </h6>
                            </b-col>
                            <b-col cols="6">
                                <h6 class="text-black size16 text-center">
                                    Hormat Kami
                                </h6>
                            </b-col>
                        </b-row>

                    </div>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BImg,
        BContainer,
    },
    data() {
        return {
        }
    },
    props: {
        detailRetur: {
            type: Object,
        },
        merchant: {
            type: Object,
        },
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.table thead tr th {
    font-weight: 800;
    color: #2B324F;
    font-size: 16px;
}

.table tbody tr td {
    font-weight: 500;
    color: #2B324F;
    font-size: 16px;
}

.bg-wave {
    background-image: url('../assets/images/wave-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

button {
    &.bg-white {
        background-color: #FFFFFF !important;
        border: 1px solid $light--3 !important;
        color: $dark !important;
    }
}

label {
    font-size: 14px;
    color: $dark;
    font-weight: 600;
}

.vs__open-indicator {
    fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
    height: 52px;
    background: #EFF1F5;
    border: 1px solid #E4E5EC;
    border-radius: 8px;
}

.vs__dropdown-toggle {
    height: 52px;
    background-color: #EFF1F5;
    border: 1px solid #E4E5EC;
    border-radius: 8px;
}
</style>
